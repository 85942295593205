import React, { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import ReactPlayer from 'react-player';

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

import IMG_2465 from './images/IMG_2465.png';
import IMG_2901 from './images/IMG_2901.png';
import IMG_2944 from './images/IMG_2944.png';
import IMG_2937 from './images/IMG_2937.png';
import IMG_2953 from './images/IMG_2953.png';
import IMG_2440 from './images/IMG_2440.png';

import biotag from './images/biotag.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {

  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    try {
      document.createEvent("TouchEvent");
      setIsMobile(true);
    } catch (e) {
      setIsMobile(false);
    }
  }, []);

  const { isLoading, error } = useAuth0();


  const tracks = [

    {
      title: "YA POIDU POIDU (feat. xexexena)",
      artist: "malchick",
      url: `https://soundcloud.com/malchiquito/ya-poidu-poidu`
    },

    {
      title: "Танго Спорт",
      artist: "Toki Tango",
      url: `https://soundcloud.com/tokitango/1zxlyqidu5ft`
    },

    {
      title: "RUSKAYA (feat. xexexena)",
      artist: "malchick",
      url: `https://soundcloud.com/malchiquito/ruskaya`
    },

    {
      title: "Мастер Спорта",
      artist: "Toki Tango",
      url: `https://soundcloud.com/tokitango/4rzxhzaan3h7`
    },

  ];

  const images = [
    IMG_2944,
    IMG_2953,
    IMG_2465,
    IMG_2901,
    IMG_2937,
    IMG_2440,
  ];

  const [current, setCurrent] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [count, setCount] = useState(0);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(prevCount => prevCount == images.length - 1 ? 0 : prevCount + 1);
    }, 50000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div className={`app ${isMobile ? "mobile" : ""}`}>
        <div className='viewer'>
          <div className='images'>
            <img className='image' src={images[count]} />
          </div>
          <div className='text'>
            {Array.from(Array(20).keys()).map(i => {
              return <div
                className={`biopunk biopunk${i + 1}`}
                style={{ animation: `marquee 200s linear ${i * 10}s infinite` }}
              ><img src={biotag} /></div>;
            })}
          </div>
        </div>
        <div className='player'>
          <div className='player-button' style={{ cursor: 'pointer' }} onClick={() => { setPlaying(!playing); }}>
            {playing ? "⏸︎" : "▶"}
          </div>
          <div className='player-title'>
            <b>{tracks[current].artist}</b>{" | "}{tracks[current].title}
          </div>
          {playing && <div className='player-bar'></div>}
          <div className='player-controls'>

            {!isMobile && <div className='player-button' onClick={() => { setCurrent(current == 0 ? tracks.length - 1 : current - 1); }}>⏮︎</div>}{" "}<div className='player-button' onClick={() => {
              if (isMobile) setPlaying(false);
              setCurrent(current == tracks.length - 1 ? 0 : current + 1);
            }}>⏭︎</div>
          </div>
          <ReactPlayer
            url={tracks[current].url}
            height='0'
            width='100vw'
            playing={playing}
            style={{ opacity: '0' }}
            soundcloud={{ options: { auto_play: playing } }}
            onDuration={(duration) => { setDuration(duration); }}
            onProgress={(progress) => {
              if (playing && duration && progress.playedSeconds == 0) {
                if (isMobile) setPlaying(false);
                setCurrent(current == tracks.length - 1 ? 0 : current + 1);
              }
            }}
          />
        </div>
      </div>
    </Router>
  );
};

export default App;
